import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Title = styled.h1`
  font-size: 1.375rem;
  font-weight: 500;
`;

function TermsPage() {
  return (
    <Layout breakCrumbs={[{label: "Điều khoản"}]}>
      <SEO title="Điều khoản" />
      <Title>Điều khoản sử dụng Tính 1s</Title>
      <p>
        Vui lòng đọc Điều khoản sử dụng này nếu bạn đang xem xét sử dụng bất cứ tài liệu, dịch vụ
        hoặc thông tin nào hiển thị trên trang của Tinh1s.com. Lưu ý rằng bằng việc truy cập và/hoặc
        sử dụng trang web của chúng tôi, bạn đồng ý với những Điều khoản sử dụng này. Nếu bạn không
        đồng ý với Điều khoản sử dụng của chúng tôi, hãy ngưng kết nối và không sử dụng Trang này.
      </p>
      <h4>Tổng quan</h4>
      <p>
        Website này và mọi trang phụ liên quan (gọi chung là Trang) được xuất bản và duy trì bởi
        TechArtVN. Khi sử dụng Trang, bạn đồng ý với các điều khoản và điều kiện được nêu rõ trong
        thông báo pháp lý này, trong Chính sách bảo mật và mọi luật và quy định hiện hành, bao gồm
        nhưng không giới hạn ở các quy định kiểm soát xuất khẩu (gọi chung là  Điều khoản sử dụng).
        Bạn tuyên bố mình có quyền hợp pháp để chấp nhận Điều khoản sử dụng này thay mặt cho chính
        bạn cũng như cho bất cứ bên nào mà bạn đại diện. Nếu bạn không đồng ý với Điều khoản sử dụng
        của chúng tôi, vui lòng không sử dụng Trang.
      </p>
      <h4>Quyền sở hữu</h4>
      <p>
        Tính 1s không thu thập hay lưu dữ bất cứ thông tin nào từ phía người dùng, chúng tôi luôn
        đảm bảo dữ liệu mà bạn cung cấp không ai có thể nhìn thấy được kể cả nhân viên của chúng
        tôi.
      </p>
      <h4>Thông tin liên hệ</h4>
      <p>
        Chúng tôi luôn trân trọng, ghi nhận và cam kết giải quyết một cách nhanh chóng và phù hợp
        nhất đối với mọi ý kiến đóng góp, câu hỏi, thắc mắc, yêu cầu của bạn. Mọi ý kiến đóng góp,
        câu hỏi, thắc mắc xin vui lòng gửi về
        <strong>hop.ho@techart.vn</strong>
      </p>
    </Layout>
  );
}

export default TermsPage;
